import React from 'react';
import Loadable from 'react-loadable';
import { FeatureLayoutConfigurationProps } from '../featureConfigurationLayout/FeatureLayoutConfigurationPage';
import { UploadDocumentDialogProps } from '../matterSummary/dialogs/UploadDocumentDialog';

export const WriteOffOnSummaryDrilldownPage = Loadable({
  loader: () => import (/* webpackChunkName: "WriteOffOnSummaryDrilldownPage" */ '../writeOffOnSummaryDrilldown/WriteOffOnSummaryDrilldownPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.WriteOffOnSummaryDrilldownPage;
    return <Component {...props}/>;
  }
});

export const NotesSummaryDrilldownPage = Loadable({
  loader: () => import (/* webpackChunkName: "NotesSummaryDrilldownPage" */ '../notesSummaryDrilldown/NotesSummaryDrilldownPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.NotesSummaryDrilldownPage;
    return <Component {...props}/>;
  }
});

export const NotesSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "NotesSummaryPage" */ '../notesSummary/NotesSummaryPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.NotesSummaryPage;
    return <Component {...props}/>;
  }
});

export const ClientDocumentsPage = Loadable({
  loader: () => import (/* webpackChunkName: "ClientDocumentsPage" */ '../clientDocuments/ClientDocumentsPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.ClientDocumentsPage;
    return <Component {...props}/>;
  }
});

export const MatterColumnSecurityConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "MatterColumnSecurityConfigurationPage" */ '../matterColumnSecurityConfiguration/MatterColumnSecurityConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.MatterColumnSecurityConfigurationPage;
    return <Component {...props}/>;
  }
});

export const RoleSecurityConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "RoleSecurityConfigurationPage" */ '../roleSecurityConfiguration/RoleSecurityConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.RoleSecurityConfigurationPage;
    return <Component {...props}/>;
  }
});

export const TimesheetResourceCenterPage = Loadable({
  loader: () => import (/* webpackChunkName: "TimesheetResourceCenterPage" */ '../timesheetResourceCenter/TimesheetResourceCenterPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.TimesheetResourceCenterPage;
    return <Component {...props}/>;
  }
});

export const TaskResourceCenterPage = Loadable({
  loader: () => import (/* webpackChunkName: "TaskResourceCenterPage" */ '../taskCrmResourceCenter/TaskResourceCenterPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.TaskResourceCenterPage;
    return <Component {...props}/>;
  }
});

export const CrmResourceCenterPage = Loadable({
  loader: () => import (/* webpackChunkName: "CrmResourceCenterPage" */ '../resourceCenter/CrmResourceCenterPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.CrmResourceCenterPage;
    return <Component {...props}/>;
  }
});

export const AcuityResourceCenterPage = Loadable({
  loader: () => import (/* webpackChunkName: "AcuityResourceCenterPage" */ '../resourceCenter/AcuityResourceCenterPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.AcuityResourceCenterPage;
    return <Component {...props}/>;
  }
});

export const MattersResourceCenterPage = Loadable({
  loader: () => import (/* webpackChunkName: "MattersResourceCenterPage" */ '../resourceCenter/MattersResourceCenterPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.MattersResourceCenterPage;
    return <Component {...props}/>;
  }
});

export const GeneralMaterialConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "GeneralMaterialConfigurationPage" */ '../generalMaterialConfiguration/GeneralMaterialConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.GeneralMaterialConfigurationPage;
    return <Component {...props}/>;
  }
});

export const Home = Loadable({
  loader: () => import (/* webpackChunkName: "Home" */ '../home/Home'),
  loading: () => null,
  // tslint:disable-next-line: no-any typedef
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.Home;
    return <Component {...props}/>;
  }
});

export const ChartComponents = Loadable({
  loader: () => import (/* webpackChunkName: "ChartComponents" */ '../home/ChartComponents'),
  loading: () => null,
  // tslint:disable-next-line: no-any typedef
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.ChartComponents;
    return <Component {...props}/>;
  }
});

export const TaskSummaryDrilldownPage = Loadable({
  loader: () => import (/* webpackChunkName: "TaskSummaryDrilldownPage" */ '../taskSummaryDrilldown/TaskSummaryDrilldownPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.TaskSummaryDrilldownPage;
    return <Component {...props}/>;
  }
});

export const EventsAndCampaignsAttendeePage = Loadable({
  loader: () => import (/* webpackChunkName: "EventsAndCampaignsAttendeePage" */ '../eventsAndCampaignsAttendee/EventsAndCampaignsAttendeePage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.EventsAndCampaignsAttendeePage;
    return <Component {...props}/>;
  }
});

export const UserDashboardActivityListPage = Loadable({
  loader: () => import (/* webpackChunkName: "UserDashboardActivityListPage" */ '../userDashboardActivityList/UserDashboardActivityListPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.UserDashboardActivityListPage;
    return <Component {...props}/>;
  }
});

export const EventsAndCampaignsPage = Loadable({
  loader: () => import (/* webpackChunkName: "EventsAndCampaignsPage" */ '../eventsAndCampaigns/EventsAndCampaignsPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.EventsAndCampaignsPage;
    return <Component {...props}/>;
  }
});

export const BrandConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "BrandConfigurationPage" */ '../brandConfiguration/BrandConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.BrandConfigurationPage;
    return <Component {...props}/>;
  }
});

export const ClientDashboardPage = Loadable({
  loader: () => import (/* webpackChunkName: "TaskDashboardPage" */ '../clientDashboard/ClientDashboardPage'),
  loading: () => null,
  // tslint:disable-next-line: no-any typedef
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.ClientDashboardPage;
    return <Component {...props}/>;
  }
});

export const TaskDashboardPage = Loadable({
  loader: () => import (/* webpackChunkName: "TaskDashboardPage" */ '../taskDashboard/TaskDashboardPage'),
  loading: () => null,
  // tslint:disable-next-line: no-any typedef
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.TaskDashboardPage;
    return <Component {...props}/>;
  }
});

export const FeeEarnerDashboardPage = Loadable({
  loader: () => import (/* webpackChunkName: "FeeEarnerDashboardPage" */ '../feeEarnerDashboard/FeeEarnerDashboardPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.FeeEarnerDashboardPage;
    return <Component {...props}/>;
  }
});

export const Dashboard = Loadable({
    loader: () => import (/* webpackChunkName: "MatterSummary" */ '../Dashboard'),
    loading: () => null
});
  
export const MatterSummary = Loadable({
    loader: () => import (/* webpackChunkName: "MatterSummary" */ '../matterSummary/MatterSummaryPageX'),
    loading: () => null
});

export const MatterSummaryDrillDown = Loadable({
    loader: () => import (/* webpackChunkName: "MatterSummary" */ '../matterSummaryDrillDown/MatterSummaryDrillDownPage'),
    loading: () => null
});

// export const MatterManagement = Loadable({
//     loader: () => import (/* webpackChunkName: "MatterManagement" */ '../matterManagement/MatterManagementPage'),
//     loading: () => null
// });
  
export const MatterDetails = Loadable({
    loader: () => import (/* webpackChunkName: "MatterDetails" */ '../matterDetails/MatterDetails'),
    loading: () => null
});
  
export const ClientAndContactDetails = Loadable({
  loader: () => import(/* webpackChunkName: "ClientAndContactDetails" */ '../ClientAndContactDetails/ClientAndContactDetails'),
  loading: () => null
});
  
export const Redirect = Loadable({
    loader: () => import(/* webpackChunkName: "Redirect" */ './Redirect'),
    loading: () => null
  });
  
export const RvPrint = Loadable({
    loader: () => import(/* webpackChunkName: "Print" */ './Print'),
    loading: () => null
  });
  
export const RvPrint2 = Loadable({
    loader: () => import(/* webpackChunkName: "Print2" */ './Print2'),
    loading: () => null
  });
  
export const Logout = Loadable({
    loader: () => import(/* webpackChunkName: "Logout" */ './Logout'),
    loading: () => null
  });
  
export const RvDashboardOld = Loadable({
    loader: () => import(/* webpackChunkName: "DashboardPage" */ '../Dashboard/DashboardPageOld'),
    loading: () => null
  });
  
export const Settings = Loadable({
    loader: () => import(/* webpackChunkName: "Settings" */ '../Settings'),
    loading: () => null
  });

export const MatterSummaryView = Loadable({
    loader: () =>
      import (/* webpackChunkName: "MatterSummaryView" */ '../matterSummary/MatterSummaryView'),
    loading: () => null
  });

export const MatterSummaryListView = Loadable({
    loader: () =>
      import (/* webpackChunkName: "MatterSummaryListView" */ '../matterSummary/MatterSummaryListView'),
    loading: () => null
  });

export const MatterSummaryCardView = Loadable({
    loader: () =>
      import (/* webpackChunkName: "MatterSummaryCardView" */ '../matterSummary/MatterSummaryCardView'),
    loading: () => null
  });  

export const LeapProvisioning = Loadable({
  loader: () =>
    import (/* webpackChunkName: "LeapProvisioning" */ './Leap/LeapProvisioning'),
  loading: () => null
});  

export const LeapSignIn = Loadable({
  loader: () =>
    import (/* webpackChunkName: "LeapSignIn" */ './Leap/LeapSignIn'),
  loading: () => null
});  

export const LeapAccessResetSignIn = Loadable({
  loader: () =>
    import (/* webpackChunkName: "LeapAccessResetSignIn" */ './Leap/LeapAccessResetSignIn'),
  loading: () => null
});  

export const LeapAccessReset = Loadable({
  loader: () =>
    import (/* webpackChunkName: "LeapAccessReset" */ './Leap/LeapAccessReset'),
  loading: () => null
}); 

export const TimeslipPage = Loadable({
  loader: () =>
    import (/* webpackChunkName: "LeapAccessReset" */ '../timeslip/TimeslipPage'),
  loading: () => null
}); 

export const LeapProvisioningInProgress = Loadable({
  loader: () =>
    import (/* webpackChunkName: "LeapSignIn" */ './Leap/LeapProvisioningInProgress'),
  loading: () => null
});  

export const VerifyNewUser = Loadable({
  loader: () =>
    import (/* webpackChunkName: "VerifyNewUser" */ './VerifyNewUser'),
  loading: () => null
});  
  
export const Unauthorised = Loadable({
  loader: () => import (/* webpackChunkName: "Unauthorised" */ '../Unauthorised'),
  loading: () => null
});

export const AutoSignIn = Loadable({
  loader: () => import (/* webpackChunkName: "AutoSignIn" */ '../infrastructure/AutoSignIn'),
  loading: () => null
});

export const ExternalEnquiry = Loadable({
  loader: () => import (/* webpackChunkName: "ExternalEnquiry" */ '../addIn/ExternalEnquiry'),
  loading: () => null
});

export const UserDashboard = Loadable({
  loader: () => import (/* webpackChunkName: "UserDashboard" */ '../userDashboard/UserDashboardPage'),
  loading: () => null,
  // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: React.FC<any> = loaded.UserDashboardPage;
    return <Component {...props}/>;
  }
});

export const RvAdminDashboard = Loadable({
  loader: () => import (/* webpackChunkName: "RvAdminDashboard" */ '../Dashboard/AdminDashboard'),
  loading: () => null
});

export const MessageActivityPage = Loadable({
  loader: () => import (/* webpackChunkName: "MessageActivityPage" */ '../Dashboard/messageActivty/MessageActivityPage'),
  loading: () => null
});

export const RoleSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "RoleSummaryPage" */ '../Dashboard/roleSummary/RoleSummaryPage'),
  loading: () => null
});

export const TimesheetActivityPage = Loadable({
  loader: () => import (/* webpackChunkName: "TimesheetActivityPage" */ '../Dashboard/timesheetActivity/TimesheetActivityPage'),
  loading: () => null
});

export const UserListPage = Loadable({
  loader: () => import (/* webpackChunkName: "UserListPage" */ '../Dashboard/userList/UserListPage'),
  loading: () => null
});

export const TimelinesChartPage = Loadable({
  loader: () => import (/* webpackChunkName: "TimelinesChartPage" */ '../Dashboard/timesheetActivity/TimelinesChartPage'),
  loading: () => null
});

export const MatterAccessChartPage = Loadable({
  loader: () => import (/* webpackChunkName: "MatterAccessChartPage" */ '../Dashboard/matterAccess/MatterAccessChartPage'),
  loading: () => null
});

export const EmailSendFailureChartPage = Loadable({
  loader: () => import (/* webpackChunkName: "EmailSendFailureChartPage" */ '../Dashboard/emailSendFailure/EmailSendFailureChartPage'),
  loading: () => null
});

export const EmailSendFailurePage = Loadable({
  loader: () => import (/* webpackChunkName: "EmailSendFailurePage" */ '../Dashboard/emailSendFailure/EmailSendFailurePage'),
  loading: () => null
});

export const MatterAccessPage = Loadable({
  loader: () => import (/* webpackChunkName: "MatterAccessPage" */ '../Dashboard/matterAccess/MatterAccessPage'),
  loading: () => null
});

export const NameSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "NameSummaryPage" */ '../nameSummary/NameSummaryPage'),
  loading: () => null
});

export const StaffSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "StaffSummaryPage" */ '../staffSummary/StaffSummaryPage'),
  loading: () => null
});

export const ReportsPage = Loadable({
  loader: () => import (/* webpackChunkName: "ReportsPage" */ '../reports/ReportsPage'),
  loading: () => null
});

export const ReportsCatalogPage = Loadable({
  loader: () => import (/* webpackChunkName: "ReportsCatalogPage" */ '../reportsCatalog/ReportsCatalogPage'),
  loading: () => null
});

export const ReportInformation = Loadable({
  loader: () => import (/* webpackChunkName: "ReportInformation" */ '../reports/ReportInformation'),
  loading: () => null
});

export const ReportViewer = Loadable({
  loader: () => import (/* webpackChunkName: "ReportViewer" */ '../reports/ReportViewer'),
  loading: () => null
});

export const ReportCatalogInformation = Loadable({
  loader: () => import (/* webpackChunkName: "ReportCatalogInformation" */ '../reportsCatalog/ReportCatalogInformation'),
  loading: () => null
});

export const TimesheetPage = Loadable({
  loader: () => import (/* webpackChunkName: "TimesheetPage" */ '../timesheet/TimesheetPage'),
  loading: () => null
});

export const LawyerDashboardPage = Loadable({
  loader: () => import (/* webpackChunkName: "LawyerDashboardPage" */ '../lawyerDashboard/LawyerDashboardPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.LawyerDashboardPage;
    return <Component {...props}/>;
  }
});

export const FeatureLayoutConfigurationPage: React.ComponentType<FeatureLayoutConfigurationProps> = Loadable({
  loader: () => import (/* webpackChunkName: "FeatureLayoutConfigurationPage" */ '../featureConfigurationLayout/FeatureLayoutConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.FeatureLayoutConfigurationPage;
    return <Component {...props}/>;
  }
});

export const ConfigurationDashboardPage = Loadable({
  loader: () => import (/* webpackChunkName: "ConfigurationDashboardPage" */ '../configurationDashboard/ConfigurationDashboardPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.ConfigurationDashboardPage;
    return <Component {...props}/>;
  }
});

export const FeatureLicenceConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "FeatureLicenceConfigurationPage" */ '../featureLicenceConfiguration/FeatureLicenceConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.FeatureLicenceConfigurationPage;
    return <Component {...props}/>;
  }
});

export const FeatureAccessConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "FeatureAccessConfigurationPage" */ '../featureAccessConfiguration/FeatureAccessConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.FeatureAccessConfigurationPage;
    return <Component {...props}/>;
  }
});

export const LeadsDashboardPage = Loadable({
  loader: () => import (/* webpackChunkName: "LeadsDashboardPage" */ '../leadsDashboard/LeadsDashboardPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.LeadsDashboardPage;
    return <Component {...props}/>;
  }
});

export const MatterManagementPage = Loadable({
  loader: () => import (/* webpackChunkName: "MatterManagementPage" */ '../matterManagement/MatterManagementPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.MatterManagementPage;
    return <Component {...props}/>;
  }
});

export const EnquirySummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "EnquirySummaryPage" */ '../enquirySummary/EnquirySummaryPage'),
  loading: () => null,
  // tslint:disable-next-line: no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.EnquirySummaryPage;
    return <Component {...props}/>;
  }
});

export const LeadsDetailPage = Loadable({
  loader: () => import (/* webpackChunkName: "LeadsDetailPage" */ '../leadsDetail/LeadsDetailPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.LeadsDetailPage;
    return <Component {...props}/>;
  }
});

export const LookupConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "LookupConfigurationPage" */ '../lookupConfiguration/LookupConfigurationPage'),
  loading: () => null
});

export const MarketingListAccessPage = Loadable({
  loader: () => import (/* webpackChunkName: "NameAccessPage" */ '../Dashboard/marketingListAccess/MarketingListAccessPage'),
  loading: () => null
});

export const MarketingListAccessChartPage = Loadable({
  loader: () => import (/* webpackChunkName: "NameAccessChartPage" */ '../Dashboard/marketingListAccess/MarketingListAccessChartPage'),
  loading: () => null
});

export const NameAccessPage = Loadable({
  loader: () => import (/* webpackChunkName: "NameAccessPage" */ '../Dashboard/nameAccess/NameAccessPage'),
  loading: () => null
});

export const NameAccessChartPage = Loadable({
  loader: () => import (/* webpackChunkName: "NameAccessChartPage" */ '../Dashboard/nameAccess/NameAccessChartPage'),
  loading: () => null
});

export const LoginActivityChartPage = Loadable({
  loader: () => import (/* webpackChunkName: "LoginActivityChartPage" */ '../Dashboard/loginAcitivity/LoginActivityChartPage'),
  loading: () => null
});

export const DashboardAccessPage = Loadable({
  loader: () => import (/* webpackChunkName: "DashboardAccessPage" */ '../Dashboard/dashboardAccess/DashboardAccessPage'),
  loading: () => null,
  // tslint:disable-next-line: no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.DashboardAccessPage;
    return <Component {...props}/>;
  }
});

export const LoginActivityPage = Loadable({
  loader: () => import (/* webpackChunkName: "LoginActivityPage" */ '../Dashboard/loginAcitivity/LoginActivityPage'),
  loading: () => null
});

export const TasksSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "TasksSummaryPage" */ '../tasksSummary/TasksSummaryPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.TasksSummaryPage;
    return <Component {...props}/>;
  }
});

export const SplashScreenConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "SplashScreenConfigurationPage" */ '../splashScreenConfiguration/SplashScreenConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.SplashScreenConfigurationPage;
    return <Component {...props}/>;
  }
});

export const EmailTemplatesConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "EmailTemplatesConfigurationPage" */ '../emailTemplatesConfiguration/EmailTemplatesConfigurationPage'),
  loading: () => null
});

export const HelpConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "HelpConfigurationPage" */ '../helpConfiguration/HelpConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.HelpConfigurationPage;
    return <Component {...props}/>;
  }
});

export const ReferrersPage = Loadable({
  loader: () => import (/* webpackChunkName: "ReferrersPage" */ '../referrers/ReferrersPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.ReferrersPage;
    return <Component {...props}/>;
  }
});

export const MailingListPage = Loadable({
  loader: () => import (/* webpackChunkName: "MailingListPage" */ '../MailingList/MailingListPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.MailingListPage;
    return <Component {...props}/>;
  }
});

export const GeneralMaterialPage = Loadable({
  loader: () => import (/* webpackChunkName: "GeneralMaterialPage" */ '../GeneralMaterial/GeneralMaterialPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.GeneralMaterialPage;
    return <Component {...props}/>;
  }
});

export const UploadDocumentDialog: React.ComponentType<UploadDocumentDialogProps> = Loadable({
  loader: () => import (/* webpackChunkName: "UploadDocumentDialog" */ '../matterSummary/dialogs/UploadDocumentDialog'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.UploadDocumentDialog;
    return <Component {...props}/>;
  }
});

export const UserConfigurationPage = Loadable({
  loader: () => import (/* webpackChunkName: "UserConfigurationPage" */ '../userConfigurationPage/UserConfigurationPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.UserConfigurationPage;
    return <Component {...props}/>;
  }
});

export const CreateEnquiryPage = Loadable({
  loader: () => import (/* webpackChunkName: "CreateEnquiryPage" */ '../addIn/CreateEnquiryPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.CreateEnquiryPage;
    return <Component {...props}/>;
  }
});

export const InvoiceSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "InvoiceSummaryPage" */ '../invoiceSummary/InvoiceSummaryPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.InvoiceSummaryPage;
    return <Component {...props}/>;
  }
});

export const TimesheetDrilldownPage = Loadable({
  loader: () => import (/* webpackChunkName: "TimesheetDrilldownPage" */ '../timesheetDrilldown/TimesheetDrilldownPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.TimesheetDrilldownPage;
    return <Component {...props}/>;
  }
});

export const StaffBillingSummaryPage = Loadable({
  loader: () => import (/* webpackChunkName: "StaffBillingSummaryPage" */ '../staffBillingSummary/StaffBillingSummaryPage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.StaffBillingSummaryPage;
    return <Component {...props}/>;
  }
});

export const LearnMorePage = Loadable({
  loader: () => import (/* webpackChunkName: "LearnMorePage" */ '../learnMore/LearnMorePage'),
  loading: () => null,
   // tslint:disable-next-line: typedef no-any
  render(loaded: any, props: any) {
    // tslint:disable-next-line: no-any
    let Component: any = loaded.LearnMorePage;
    return <Component {...props}/>;
  }
});

export const PreloadComponents = () => {    
    RvPrint.preload();
    RvPrint2.preload();
    // Dashboard.preload();
    // RvDashboardOld.preload();
    Settings.preload();    
    Logout.preload();
    MatterDetails.preload();
    MatterSummary.preload();
    MatterSummaryView.preload();
    MatterSummaryListView.preload();
    MatterSummaryCardView.preload();
    ChartComponents.preload();
};

export const PreloadMatterSummary = () => {
    MatterDetails.preload();
    MatterSummary.preload();
    MatterSummaryView.preload();
    MatterSummaryListView.preload();
    MatterSummaryCardView.preload();
    ChartComponents.preload();
};