import * as React from 'react';
import { RvStringDisplay } from './StringDisplay';
import { RvDateTimeDisplay } from './DateTimeDisplay';
import { CurrencyDisplay } from './CurrencyDisplay';
import { NumberDisplay } from './NumberDisplay';
import { CurrencyDisplayStyle } from './FormattedCurrency';
import { LayoutWidth } from '../Label/LayoutWidth';
import { DateTimeDisplayStyle } from './FormattedDateTime';

export var DataType = {
    Default: 0,     // string
    String: 1,      // to verify
    Date: 2,
    Number: 3,       // use the formatter
    Currency: 4,
    Boolean: 5,
    DateTime: 6,
    Image: 7,
    Percentage: 8,
    ContextMenu: 9,
    Link: 10,
    LongText: 11,
    Checkbox: 12,
    CurrencyWithoutDecimals: 13,
    EditorView: 14,
    Duration: 15,
    DueDate: 16,
    Chips: 17,
    ContextMenuStaff: 18,
    Rating: 19,
    EventCampaignCategory: 20,
    MultiLineString: 21,
};

interface DisplayModel {
    stringFormat: string;
    dataType: number; // use the DataTopicType exported from RvDataTopicInfo
    currencyDisplayStyle?: CurrencyDisplayStyle;
}

export function labelAndValueFormatter (dataType: number, label: string, value: string | Date,
                                        dateFormat?: string, idx?: string) {

    let displayModel: DisplayModel = dateFormat ? getDataFormat(dateFormat) : getDataFormat('');
    let numericValue: number = value ? Number.parseFloat(value as string) : 0;    
    switch (dataType) {
        default:
            return (
                <RvStringDisplay
                    key={idx}
                    label={label}
                    value={value as string}
                    LabelDataWidth={LayoutWidth.four}
                    ValueDataWidth={LayoutWidth.twelve}
                />
            );
        case DataType.Number:
            if (displayModel && displayModel.currencyDisplayStyle) {
                return (
                    <CurrencyDisplay
                        key={idx}
                        label={label}
                        value={numericValue}
                        displayStyle={displayModel.currencyDisplayStyle}
                        LabelDataWidth={LayoutWidth.four}
                        ValueDataWidth={LayoutWidth.twelve}
                    />
                );
            } else {
                return (
                    <NumberDisplay
                        key={idx}
                        label={label}
                        value={numericValue}
                        LabelDataWidth={LayoutWidth.four}
                        ValueDataWidth={LayoutWidth.twelve}
                    />
                );
            }
        case DataType.Default:
        case DataType.String:
            return (
                <RvStringDisplay
                    key={idx}
                    label={label}
                    value={value as string}
                    LabelDataWidth={LayoutWidth.four}
                    ValueDataWidth={LayoutWidth.twelve}
                />
            );
        case DataType.Date:
            return (
                <RvDateTimeDisplay
                    key={idx}
                    label={label}
                    value={value as Date}
                    LabelDataWidth={LayoutWidth.four}
                    ValueDataWidth={LayoutWidth.twelve}
                />
            );
        case DataType.Currency:
            return (
                <CurrencyDisplay
                    key={idx}
                    label={label}
                    value={numericValue}
                    displayStyle={CurrencyDisplayStyle.Decimal}
                    LabelDataWidth={LayoutWidth.four}
                    ValueDataWidth={LayoutWidth.twelve}
                />
            );
        case DataType.DateTime:
            return (
                <RvDateTimeDisplay
                    key={idx}
                    label={label}
                    value={value as Date}
                    displayStyle={DateTimeDisplayStyle.ShortDateTime}
                    LabelDataWidth={LayoutWidth.four}
                    ValueDataWidth={LayoutWidth.twelve}
                />
            );

    }
}

var getDataFormat = (param: string): DisplayModel => {
    let returnValue: DisplayModel = {
        stringFormat: '', 
        dataType: DataType.String, 
        currencyDisplayStyle: CurrencyDisplayStyle.Decimal
    };
    switch (param) {
        case 'N1':
            returnValue = {
                stringFormat: '0:###,###,###.00', dataType: DataType.Number,
                currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N2':
            returnValue = {
                stringFormat: '0:###,###,###', dataType: DataType.Number,
                currencyDisplayStyle: CurrencyDisplayStyle.Default
            };
            break;
        case 'N3':
            returnValue = {
                stringFormat: '0:###,###,###.00', dataType: DataType.Number,
                currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N4':
            returnValue = {
                stringFormat: '0:###,###,###', dataType: DataType.Number,
                currencyDisplayStyle: CurrencyDisplayStyle.Custom
            };
            break;
        case 'N5':
            returnValue = {
                stringFormat: '0:###,###,###.00;###,###,###.00',
                dataType: DataType.Number, currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N6':
            returnValue = {
                stringFormat: '0:###,###,###.00;###,###,###.00',
                dataType: DataType.Number, currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N7':
            returnValue = {
                stringFormat: '0:###,###,###.00;###,###,###.00;Nil',
                dataType: DataType.Number, currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N8':
            returnValue = {
                stringFormat: '0:###,###,###.00;###,###,###.00;Nil',
                dataType: DataType.Number, currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N9':
            returnValue = { stringFormat: '', dataType: DataType.Number };
            break;
        case 'N10':
            returnValue = { stringFormat: '', dataType: DataType.Number };
            break;
        case 'N11':
            returnValue = {
                stringFormat: '0:###,###,###.00', dataType: DataType.Number,
                currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
        case 'N12':
            returnValue = { stringFormat: '', dataType: DataType.Number };
            break;
        case 'N13':
            returnValue = { stringFormat: '', dataType: DataType.Number };
            break;
        case 'N14':
            returnValue = { stringFormat: '', dataType: DataType.Number };
            break;
        case 'N15':
            returnValue = { stringFormat: '', dataType: DataType.Number };
            break;

        case 'D1':
            returnValue = { stringFormat: '0:dd/MM/yy', dataType: DataType.Date };
            break;
        case 'D2':
            returnValue = { stringFormat: '0:dddd, dd MMM yy', dataType: DataType.Date };
            break;
        case 'D3':
            returnValue = { stringFormat: '0:dd MMM yyyy', dataType: DataType.Date };
            break;
        case 'D4':
            returnValue = { stringFormat: '0:ddd dd MM yy', dataType: DataType.Date };
            break;
        case 'D5':
            returnValue = { stringFormat: '0:dd MMM yy', dataType: DataType.Date };
            break;
        case 'D6':
            returnValue = { stringFormat: '0:dd/MM/yy', dataType: DataType.Date };
            break;
        case 'D7':
            returnValue = { stringFormat: '0:dddd, dd MMMM yyyy', dataType: DataType.Date };
            break;
        case 'D8':
            returnValue = { stringFormat: '0:dd MMMM yyyy', dataType: DataType.Date };
            break;
        default:
            returnValue = {
                stringFormat: '0:###,###,###.00', dataType: DataType.Number,
                currencyDisplayStyle: CurrencyDisplayStyle.Decimal
            };
            break;
    }

    return returnValue;
};
